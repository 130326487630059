<template>
  <CDropdown
    inNav
    class="c-header-nav-items"
    placement="bottom-end"
  >
    <template #toggler>
      <CHeaderNavLink>
        <CIcon name="cil-user" /> {{ username }}
      </CHeaderNavLink>
    </template>
    <CDropdownItem to="/update-password">
      <CIcon name="cil-shield-alt" /> Update Password
    </CDropdownItem>
    <CDropdownItem @click="logout">
      <CIcon name="cil-lock-locked" /> Logout
    </CDropdownItem>
  </CDropdown>
</template>

<script>
import apiAccount from '@/api/account'

export default {
  name: 'TheHeaderDropdownAccnt',
  data () {
    return { 
      username: window.localStorage.getItem('username')
    }
  },
  methods: {
    logout() {

      apiAccount.logout().then(response => {
        if (response.data.status === 'successful') {

          window.localStorage.removeItem('authToken');
          window.localStorage.removeItem('userID');
          window.localStorage.removeItem('username');
          window.localStorage.removeItem('isSuper');

          alert("logout successful");

          location.reload()

        } else {

          window.localStorage.removeItem('authToken');
          window.localStorage.removeItem('userID');
          window.localStorage.removeItem('username');
          window.localStorage.removeItem('isSuper');

          alert("logout failed");

          location.reload()
        }
      }).catch((err) => {
          alert("logout error");
          console.log(err);

          window.localStorage.removeItem('authToken');
          window.localStorage.removeItem('userID');
          window.localStorage.removeItem('username');
          window.localStorage.removeItem('isSuper');

          alert("logout failed");

          location.reload()
      })

    }
  }
}
</script>

<style scoped>
  .c-icon {
    margin-right: 0.3rem;
  }
</style>